.wrapper h1 {
  /* font-family: 'Londrina Solid'; */
  font-family: Verdana, Geneva, sans-serif;
  font-size: 5rem;
  font-weight: 700;
}

@media (max-width: 992px) {
  .wrapper {
    padding: 2rem;
  }

  .wrapper h1 {
    font-size: 3.75rem;
  }
}

@media (min-width: 992px) {
  .wrapper h1 {
    font-size: 5rem;
    margin-left: 2rem;
  }
}
