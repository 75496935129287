@import './css/colors.css';

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: #fff !important;
  background-color: #000 !important;
}

.accordion-item {
  background-color: #000 !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
